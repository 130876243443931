const LayoutProvedor = () => import('../layout/LayoutProvedor.vue');

export default [{
  path: '/provedor',
  component: LayoutProvedor,
  meta: {
     forAuth : true,
  },
  children: [{
    path: 'gerais',
      children: [{
          path: 'pessoas',
          component: () => import('../components/modulos/utilitarios/pessoas/PessoasTable.vue')
      }]
  },{
      path: 'dashboard',
      component: () => import('../components/modulos/provedor/Dashboard.vue')
  },{
      path: 'gerencia',
      component: () => import('../components/modulos/provedor/Gerencia.vue'),
      name: 'provedorgerencia'
  },{
      path: 'ponto',
      component: () => import('../components/modulos/provedor/PontoTable.vue'),
      name: 'ponto'
  },{
    path: 'ponto/:id/listar',
    component: () => import('../components/modulos/provedor/PontoTable.vue'),
    name: 'pontoListar'
  },{
      path: 'ponto/criar',
      component: () => import('../components/modulos/provedor/Ponto.vue'),
      name: 'pontoCriar'
  },{
      path: 'ponto/:id',
      component: () => import('../components/modulos/provedor/Ponto.vue'),
      name: 'pontoEditar'
  },{
      path: 'tabelas/plano',
      component: () => import('../components/modulos/provedor/tabelas/PlanoTable.vue')
  },{
      path: 'tabelas/plano/criar',
      component: () => import('../components/modulos/provedor/tabelas/Plano.vue'),
      name: 'planoCriar'
  },{
    path: 'tabelas/plano/:id/listar',
    component: () => import('../components/modulos/provedor/tabelas/PlanoTable.vue'),
    name: 'planoListar'
  },{
      path: 'tabelas/horarios/:id',
      component: () => import('../components/modulos/provedor/tabelas/Plano.vue'),
      name: 'horarioEditar'
  },{
      path: 'tabelas/conexao',
      component: () => import('../components/modulos/provedor/tabelas/ConexaoTable.vue')
  },{
      path: 'tabelas/conexao/criar',
      component: () => import('../components/modulos/provedor/tabelas/Conexao.vue'),
      name: 'horariostiposCriar'
  },{
      path: 'tabelas/horariostipos/:id',
      component: () => import('../components/modulos/provedor/tabelas/Conexao.vue'),
      name: 'horariostiposEditar'
  },{
      path: 'parametro/:id',
      component: () => import('../components/modulos/provedor/Parametro.vue'),
      name: 'ProvedorParametroEditar'
  },{
      path: 'parametro',
      component: () => import('../components/modulos/provedor/ParametroTable.vue'),
      name: 'ProvedorParametroTabela'
  }]

}]
