const LayoutHotel = () => import('../layout/LayoutHotel.vue');

export default [{
  path: '/hotel',
  component: LayoutHotel,
    meta: {
        forAuth : true,
    },
  children: [{
    path: 'gerais',
      children: [{
          path: 'pessoas',
          component: () => import('../components/modulos/utilitarios/pessoas/PessoasTable.vue')
      }]
  },{
      path: 'reservas',
      component: () => import('../components/modulos/hotel/Reservas.vue')
  },{
      path: 'tabelas/apartamento',
      component: () => import('../components/modulos/hotel/tabelas/TableApartamento.vue')
  },{
      path: 'tabelas/apartamento/criar',
      component: () => import('../components/modulos/hotel/tabelas/Apartamento.vue'),
      name: 'HotelApartamentoCriar'
  },{
      path: 'tabelas/apartamento/:id',
      component: () => import('../components/modulos/hotel/tabelas/Apartamento.vue'),
      name: 'HotelApartamentoEditar'
  },{
      path: 'tabelas/mensagem',
      component: () => import('../components/modulos/hotel/tabelas/TableMensagem.vue')
  },{
      path: 'tabelas/mensagem/criar',
      component: () => import('../components/modulos/hotel/tabelas/Mensagem.vue'),
      name: 'HotelMensagemCriar'
  },{
      path: 'tabelas/mensagem/:id',
      component: () => import('../components/modulos/hotel/tabelas/Mensagem.vue'),
      name: 'HotelMensagemEditar'
  },{
      path: 'tabelas/reserva',
      component: () => import('../components/modulos/hotel/tabelas/TableReserva.vue')
  },{
      path: 'tabelas/reserva/criar',
      component: () => import('../components/modulos/hotel/tabelas/Reserva.vue'),
      name: 'HotelReservaCriar'
  },{
      path: 'tabelas/reserva/:id',
      component: () => import('../components/modulos/hotel/tabelas/Reserva.vue'),
      name: 'HotelReservaEditar'
  },{
      path: 'parametro/:id',
      component: () => import('../components/modulos/hotel/Parametro.vue'),
      name: 'HotelParametroEditar'
  },{
      path: 'parametro',
      component: () => import('../components/modulos/hotel/ParametroTable.vue'),
      name: 'HotelParametroTabela'
  }]
}]
