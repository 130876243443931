const LayoutDispositivos = () => import('../layout/LayoutDispositivos.vue');

export default [{
  path: '/dispositivos',
  component: LayoutDispositivos,
    meta: {
        forAuth : true,
    },
  children: [{
    path: 'gerais',
      children: [{
          path: 'pessoas',
          component: () => import('../components/modulos/utilitarios/pessoas/PessoasTable.vue')
      }]
  },{
      path: 'parametro/:id',
      component: () => import('../components/modulos/dispositivos/Parametro.vue'),
      name: 'DispositivosParametroEditar'
  },{
      path: 'parametro',
      component: () => import('../components/modulos/dispositivos/ParametroTable.vue'),
      name: 'DispositivosParametroTabela'
  }]
}]
