const LayoutOrcamento = () => import('../layout/LayoutOrcamento.vue');

export default [{
    path: '/orcamento',
    component: LayoutOrcamento,
    meta: {
        forAuth : true,
    },
    children: [
    {
        path: 'dashboard',
        component: () => import('../components/modulos/orcamento/Dashboard.vue'),
        name: 'orcamentoDashboard'
    },{
        path: 'orcamento',
        component: () => import('../components/modulos/orcamento/OrcamentoTable.vue')
    },{
        path: 'orcamento/criar',
        component: () => import('../components/modulos/orcamento/Orcamento.vue'),
        name: 'orcamentoCriar'
    },{
        path: 'orcamento/:id/editar',
        component: () => import('../components/modulos/orcamento/Orcamento.vue'),
        name: 'orcamentoEditar'
    },{
        path: 'orcamento/:id/visualizar',
        component: () => import('../components/modulos/orcamento/Orcamento.vue'),
        name: 'orcamentoVisualizar'
    },{
        path: 'tabelas/motivoperda',
        component: () => import('../components/modulos/orcamento/tabelas/MotivoperdaTable.vue')
    },{
        path: 'tabelas/motivoperda/criar',
        component: () => import('../components/modulos/orcamento/tabelas/Motivoperda.vue'),
        name: 'motivoperdaCriar'
    },{
        path: 'tabelas/motivoperda/:id',
        component: () => import('../components/modulos/orcamento/tabelas/Motivoperda.vue'),
        name: 'motivoperdaEditar'
    },{
        path: 'parametro/:id',
        component: () => import('../components/modulos/orcamento/Parametro.vue'),
        name: 'OrcamentoParametroEditar'
    },{
        path: 'parametro',
        component: () => import('../components/modulos/orcamento/ParametroTable.vue'),
        name: 'OrcamentoParametroTabela'
    }]
}]