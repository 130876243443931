const LayoutFazenda = () => import('../layout/LayoutFazenda.vue');

export default [{
    path: '/fazenda',
    component: LayoutFazenda,
    meta: {
        forAuth : true,
    },
    children: [
    {
        path: 'dashboard',
        component: () => import('../components/modulos/fazenda/Dashboard.vue'),
        name: 'fazendaDashboard'
    },{
        path: 'animal',
        component: () => import('../components/modulos/fazenda/AnimalTable.vue'),
        name: 'animalTabela'
    },{
        path: 'animal/criar',
        component: () => import('../components/modulos/fazenda/Animal.vue'),
        name: 'animalCriar'
    },{
        path: 'animal/:id',
        component: () => import('../components/modulos/fazenda/Animal.vue'),
        name: 'animalVisualizar'
    },{
        path: 'animal/:id/editar',
        component: () => import('../components/modulos/fazenda/Animal.vue'),
        name: 'animalEditar'
    },{
        path: 'compra',
        component: () => import('../components/modulos/fazenda/CompraTable.vue')
    },{
        path: 'compra/criar',
        component: () => import('../components/modulos/fazenda/Compra.vue'),
        name: 'compraCriar'
    },{
        path: 'compra/:id/editar',
        component: () => import('../components/modulos/fazenda/Compra.vue'),
        name: 'compraEditar'
    },{
        path: 'compra/:id/visualizar',
        component: () => import('../components/modulos/fazenda/Compra.vue'),
        name: 'compraVisualizar'
    },{
        path: 'venda',
        component: () => import('../components/modulos/fazenda/VendaTable.vue')
    },{
        path: 'venda/criar',
        component: () => import('../components/modulos/fazenda/Venda.vue'),
        name: 'vendaCriar'
    },{
        path: 'venda/:id/editar',
        component: () => import('../components/modulos/fazenda/Venda.vue'),
        name: 'vendaEditar'
    },{
        path: 'venda/:id/visualizar',
        component: () => import('../components/modulos/fazenda/Venda.vue'),
        name: 'vendaVisualizar'
    },{
        path: 'tabelas/fazenda',
        component: () => import('../components/modulos/fazenda/tabelas/FazendaTable.vue')
    },{
        path: 'tabelas/fazenda/criar',
        component: () => import('../components/modulos/fazenda/tabelas/Fazenda.vue'),
        name: 'fazendaCriar'
    },{
        path: 'tabelas/fazenda/:id',
        component: () => import('../components/modulos/fazenda/tabelas/Fazenda.vue'),
        name: 'fazendaEditar'
    },{
        path: 'tabelas/dono',
        component: () => import('../components/modulos/fazenda/tabelas/DonoTable.vue')
    },{
        path: 'tabelas/dono/criar',
        component: () => import('../components/modulos/fazenda/tabelas/Dono.vue'),
        name: 'donoCriar'
    },{
        path: 'tabelas/dono/:id',
        component: () => import('../components/modulos/fazenda/tabelas/Dono.vue'),
        name: 'donoEditar'
    },{
        path: 'tabelas/piquete',
        component: () => import('../components/modulos/fazenda/tabelas/PiqueteTable.vue')
    },{
        path: 'tabelas/piquete/criar',
        component: () => import('../components/modulos/fazenda/tabelas/Piquete.vue'),
        name: 'piqueteCriar'
    },{
        path: 'tabelas/piquete/:id',
        component: () => import('../components/modulos/fazenda/tabelas/Piquete.vue'),
        name: 'piqueteEditar'
    },{
        path: 'tabelas/pastagem',
        component: () => import('../components/modulos/fazenda/tabelas/PastagemTable.vue')
    },{
        path: 'tabelas/pastagem/criar',
        component: () => import('../components/modulos/fazenda/tabelas/Pastagem.vue'),
        name: 'pastagemCriar'
    },{
        path: 'tabelas/pastagem/:id',
        component: () => import('../components/modulos/fazenda/tabelas/Pastagem.vue'),
        name: 'pastagemEditar'
    },{
        path: 'parametro/:id',
        component: () => import('../components/modulos/fazenda/Parametro.vue'),
        name: 'FazendaParametroEditar'
    },{
        path: 'parametro',
        component: () => import('../components/modulos/fazenda/ParametroTable.vue'),
        name: 'FazendaParametroTabela'
    }]
}]