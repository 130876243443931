import { defineStore } from 'pinia'
import { server } from '../../../boot/axios'

export const useAuthorizationStore = defineStore('authorization',{
    namespaced: true,
    state: () => ({
        credentials: {
            email: '',
            password: '',
            device_name: navigator.userAgent,
            fcm_token: 'Vazio',
            rememberMe: false
        },
        me: {
            id: '',
            name: '',
            email: '',
            utilpessoa: {
                id: 0,
                nomerazao: '',
                fantasiaapelido : ''
            }
        },
        token_sanctum: localStorage.getItem('token_sanctum') || null,
        authenticated: false,
        status: '',
        errors: [],
    }),
    getters: {
        loggedIn(state){
            return state.token_sanctum !== null
        },
        getTokena(state){
            return state.token_sanctum
        },
        getUserId(state){
            return state.me.id
        },
        getUsuarioNome(state){
            return state.me.utilpessoa.fantasiaapelido
        },
        getUsuarioId(state){
            return state.me.utilpessoa.id
        },
        getHaveErrors(state){
            return state.status == 'error' ? true : false
        },
        getErrors(state){
            return state.errors
        }
    },
    actions: {
        register (params) {
            return server.post('auth/register', params)
        },
        async csrf () {
            await server.get('/sanctum/csrf-token')
                .then(response => {
                    console.debug('Csrf')
                })
        },
        async login () {
            await server.post('/api/sanctum/token', this.credentials)
                .then(response => {
                    this.token_sanctum = response.data.token
                    localStorage.setItem('token_sanctum', response.data.token);
                    localStorage.setItem('user', response.data.user);
                    //this.axiosintercept()
                    return response.data;
                })
                .catch(error => {
                    if (error.response) {
                        // O servidor retornou um código de status diferente de 2xx
                        console.error('Erro de autenticação:', error.response.data);
                        // Tratamento específico para erros de autenticação, se necessário
                    } else if (error.request) {
                        // A solicitação foi feita, mas não recebeu resposta
                        console.error('Sem resposta do servidor:', error.request);
                    } else {
                        // Ocorreu um erro ao configurar a solicitação
                        console.error('Erro ao configurar a solicitação:', error.message);
                    }
                    // Remover token apenas em caso de erro de autenticação
                    if (error.response && error.response.status === 401) {
                        localStorage.removeItem('token_sanctum');
                        localStorage.removeItem('user');
                    }
                    throw error; // Rejeitar com o erro original
                })

            //});
            // return server.post('/api/sanctum/token', params)
            //     .then(response => {
            //         commit('SET_TOKEN', response.data.token);
            //         localStorage.setItem('token_sanctum', response.data.token);
            //         localStorage.setItem('user', response.data.user);
            //         dispatch('axiosintercept');
            //         return Promise.resolve(response.data.token)                 
            //     })
            //     .catch(error => {
            //         commit('AUTH_ERROR', error)
            //         console.log(error.statusText);
            //     })
        },

        async logout () {
            const token = localStorage.getItem('token_sanctum')
            //if (!token) return;
        
                return await server.delete('/api/sanctum/logout')
                .then(response => {
                    // Removendo Local Storage
                    localStorage.removeItem('token_sanctum');
                    localStorage.removeItem('user');
                    localStorage.removeItem('uuid');
                    localStorage.removeItem('fantasia');
                    return response;
                })
                .catch(error => {
                    // Removendo Local Storage
                    localStorage.removeItem('token_sanctum');
                    localStorage.removeItem('user');
                    localStorage.removeItem('uuid');
                    localStorage.removeItem('fantasia');
                    throw error; // Rejeitar com o erro original
                })
        },

        // depois tenho que colocar aqui um intercept para enviar o UUID da empresa nas requisições
        // axiosintercept()
        // {
        //     const token = localStorage.getItem('token_sanctum')
        //     if (!token) return;
        //         server.interceptors.request.use(
        //         (config) => {
        //             let token = localStorage.getItem('token_sanctum');
        //             if (token) {
        //                 config.headers['Authorization'] = `Bearer ${ token }`;
        //             }
        //             return config;
        //         },
        //         (error) => {
        //             return Promise.reject(error);
        //         }
        //     );
        // },
        getMe () {
            return server.get('/api/sanctum/me')
                .then(response => {
                    this.$state.me = response.data
                    return Promise.resolve(response.data);
                })
                .catch(error => {
                    localStorage.removeItem('token_sanctum');
                    localStorage.removeItem('user');
                    localStorage.removeItem('uuid');
                    localStorage.removeItem('fantasia');
                    return Promise.reject(error.data);
                })
        },

        getToken(){
            var token = localStorage.getItem('token_sanctum');
            if(!token){
                return null
            }
            return token
        },

        isAuthenticated(){
            var token = localStorage.getItem('token_sanctum');
            if(token){
                return true;
            }else{
                return false;
            }
        },
        // isAuthenticated({commit}){

        //     const token = localStorage.getItem('token_sanctum')

        //     if (!token) return;

        //     server.interceptors.request.use(
        //         (config) => {
        //             let token = localStorage.getItem('token_sanctum');

        //             if (token) {
        //                 config.headers['Authorization'] = `Bearer ${ token }`;
        //             }

        //             return config;
        //         },

        //         (error) => {
        //             return Promise.reject(error);
        //         }
        //     );

        //     server.get('/api/sanctum/me')
        //     .then(response => {
        //         commit('SET_ME', response.data)
        //         commit('SET_TOKEN', token);
        //     })
        //     .catch(error => localStorage.removeItem(TOKEN_NAME))
        // },

        
    }

})