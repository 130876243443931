const LayoutFinanceiro = () => import('../layout/LayoutFinanceiro.vue');

export default [{
  path: '/financeiro',
  component: LayoutFinanceiro,
    meta: {
        forAuth : true,
    },
  children: [{
    path: 'gerais',
      children: [{
          path: 'pessoas',
          component: () => import('../components/modulos/utilitarios/pessoas/PessoasTable.vue')
      }]
  },{
    path: 'dashboard',
    component: () => import('../components/modulos/financeiro/Dashboard.vue')
  },{
    path: 'movimento/criar',
    component: () => import('../components/modulos/financeiro/Movimento.vue'),
    name: 'movivmentoCriar'
  },{
      path: 'movimento',
      component: () => import('../components/modulos/financeiro/MovimentoTable.vue'),
      name: 'movivmentoTabela'
  },{
      path: 'receber',
      component: () => import('../components/modulos/financeiro/ReceberTable.vue'),
      name: 'receberTabela'
  },{
    path: 'receber/:id',
    component: () => import('../components/modulos/financeiro/Receber.vue'),
    name: 'receberEditar'
  },{
      path: 'pagar',
      component: () => import('../components/modulos/financeiro/PagarTable.vue'),
      name: 'pagarTabela'
  },{
    path: 'modalidade/adiantamentocliente',
    component: () => import('../components/modulos/financeiro/modalidade/AdiantamentoClienteTable.vue'),
    name: 'adiantamentoClienteTabela'
  },{
    path: 'modalidade/adiantamentofornecedor',
    component: () => import('../components/modulos/financeiro/modalidade/AdiantamentoFornecedorTable.vue'),
    name: 'adiantamentoFornecedorTabela'
  },{
    path: 'modalidade/cartao',
    component: () => import('../components/modulos/financeiro/modalidade/CartaoTable.vue'),
    name: 'cartaoTabela'
  },{
    path: 'modalidade/cheque',
    component: () => import('../components/modulos/financeiro/modalidade/ChequeTable.vue'),
    name: 'chequeTabela'
  },{
      path: 'tabela/conta/:id',
      component: () => import('../components/modulos/financeiro/tabela/Conta.vue'),
      name: 'contaEditar'
  },{
    path: 'tabela/conta/:id/listar',
    component: () => import('../components/modulos/financeiro/tabela/ContaTable.vue'),
    name: 'contaListar'
  },{
    path: 'tabela/conta/criar',
    component: () => import('../components/modulos/financeiro/tabela/Conta.vue'),
    name: 'contaCriar'
},{
      path: 'tabela/conta',
      component: () => import('../components/modulos/financeiro/tabela/ContaTable.vue'),
      name: 'contaTabela'
  },{
      path: 'parametro/:id',
      component: () => import('../components/modulos/financeiro/Parametro.vue'),
      name: 'FinanceiroParametroEditar'
  },{
      path: 'parametro',
      component: () => import('../components/modulos/financeiro/ParametroTable.vue'),
      name: 'FinanceiroParametroTabela'
  }]
}]
