const LayoutAgenda = () => import('../layout/LayoutAgenda.vue');

export default [{
  path: '/agenda',
  component: LayoutAgenda,
    meta: {
        forAuth : true,
    },
  children: [{
    path: 'calendario',
    component: () => import('~/modulos/agenda/Calendario.vue'),
    name: 'AgendaCalendario'
  },{
      path: 'parametro/:id',
      component: () => import('~/modulos/agenda/Parametro.vue'),
      name: 'AgendaParametroEditar'
  },{
      path: 'parametro',
      component: () => import('~/modulos/agenda/ParametroTable.vue'),
      name: 'AgendaParametroTabela'
  }]
}]
