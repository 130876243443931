const LayoutAcademia = () => import('../layout/LayoutAcademia.vue');

export default [{
  path: '/academia',
  component: LayoutAcademia,
  meta: {
     forAuth : true,
  },
  children: [{
    path: 'gerais',
      children: [{
          path: 'pessoas',
          component: () => import('../components/modulos/utilitarios/pessoas/PessoasTable.vue')
      }]
  },{
      path: 'dashboard',
      component: () => import('../components/modulos/academia/Dashboard.vue')
      
  },{
    path: 'controle',
    component: () => import('../components/modulos/academia/Controle.vue')
  },{
      path: 'gerencia',
      component: () => import('../components/modulos/academia/Gerencia.vue'),
      name: 'gerenciaAcademia',
  },{
      path: 'mes/:ano/:mes/:professor',
      component: () => import('../components/modulos/academia/Mes.vue'),
      name: 'gerenciaMes'
  },{
      path: 'recebimento/:ano/:mes/:professor',
      component: () => import('../components/modulos/academia/Recebimento.vue'),
      name: 'recebimento'
  },{
      path: 'pagamento/:ano/:mes/:professor',
      component: () => import('../components/modulos/academia/Pagamento.vue'),
      name: 'pagamento'
  },{
    path: 'despesa/:ano/:mes/:professor',
    component: () => import('../components/modulos/academia/Despesa.vue'),
    name: 'despesa'
  },{
      path: 'matriculas',
      component: () => import('../components/modulos/academia/MatriculasTable.vue'),
      name: 'matriculas'
  },{
    path: 'matriculas/:id/listar',
    component: () => import('../components/modulos/academia/MatriculasTable.vue'),
    name: 'matriculasListar'
  },{
      path: 'matriculas/criar',
      component: () => import('../components/modulos/academia/Matriculas.vue'),
      name: 'matriculasCriar'
  },{
      path: 'matriculas/:id',
      component: () => import('../components/modulos/academia/Matriculas.vue'),
      name: 'matriculasEditar'
  },{
      path: 'tabelas/horarios',
      component: () => import('../components/modulos/academia/tabelas/HorariosTable.vue')
  },{
      path: 'tabelas/horarios/criar',
      component: () => import('../components/modulos/academia/tabelas/Horarios.vue'),
      name: 'horariosCriar'
  },{
    path: 'tabelas/horarios/:id/listar',
    component: () => import('../components/modulos/academia/tabelas/HorariosTable.vue'),
    name: 'horariosListar'
  },{
      path: 'tabelas/horarios/:id',
      component: () => import('../components/modulos/academia/tabelas/Horarios.vue'),
      name: 'horariosEditar'
  },{
      path: 'tabelas/horariostipos',
      component: () => import('../components/modulos/academia/tabelas/HorariosTiposTable.vue')
  },{
      path: 'tabelas/horariostipos/criar',
      component: () => import('../components/modulos/academia/tabelas/HorariosTipos.vue'),
      name: 'horariostiposCriar'
  },{
      path: 'tabelas/horariostipos/:id',
      component: () => import('../components/modulos/academia/tabelas/HorariosTipos.vue'),
      name: 'horariostiposEditar'
  },{
      path: 'parametro/:id',
      component: () => import('../components/modulos/academia/Parametro.vue'),
      name: 'AcademiaParametroEditar'
  },{
      path: 'parametro',
      component: () => import('../components/modulos/academia/ParametroTable.vue'),
      name: 'AcademiaParametroTabela'
  }]

}]
