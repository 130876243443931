const LayoutMercadoria = () => import('../layout/LayoutMercadoria.vue');

export default [{
  path: '/mercadoria',
  component: LayoutMercadoria,
    meta: {
        forAuth : true,
    },
  children: [{
    path: 'gerais',
      children: [{
          path: 'pessoas',
          component: () => import('../components/modulos/utilitarios/pessoas/PessoasTable.vue')
      }]
  },{
      path: 'mercadoria',
      component: () => import('../components/modulos/mercadoria/MercadoriaTable.vue')
  },{
      path: 'mercadoria/criar',
      component: () => import('../components/modulos/mercadoria/Mercadoria.vue'),
      name: 'mercadoriaCriar'
  },{
      path: 'mercadoria/:id',
      component: () => import('../components/modulos/mercadoria/Mercadoria.vue'),
      name: 'mercadoriaEditar'
  },{
      path: 'orcamento',
      component: () => import('../components/modulos/mercadoria/OrcamentoTable.vue')
  },{
      path: 'orcamento/criar',
      component: () => import('../components/modulos/mercadoria/Orcamento.vue'),
      name: 'orcamentoMercadoriaCriar'
  },{
      path: 'orcamento/:id',
      component: () => import('../components/modulos/mercadoria/Orcamento.vue'),
      name: 'orcamentoMercadoriaEditar'
  },{
      path: 'requisicao',
      component: () => import('../components/modulos/mercadoria/RequisicaoTable.vue')
  },{
      path: 'requisicao/criar',
      component: () => import('../components/modulos/mercadoria/Requisicao.vue'),
      name: 'requisicaoCriar'
  },{
      path: 'requisicao/:id',
      component: () => import('../components/modulos/mercadoria/Requisicao.vue'),
      name: 'requisicaoEditar'
  },{
      path: 'tabelas/coeficiente',
      component: () => import('../components/modulos/mercadoria/tabelas/coeficientes/CoeficienteTable.vue')
  },{
      path: 'tabelas/coeficiente/criar',
      component: () => import('../components/modulos/mercadoria/tabelas/coeficientes/Coeficiente.vue'),
      name: 'coeficienteCriar'
  },{
      path: 'tabelas/coeficiente/:id',
      component: () => import('../components/modulos/mercadoria/tabelas/coeficientes/Coeficiente.vue'),
      name: 'coeficienteEditar'
  },{
      path: 'tabelas/tipo',
      component: () => import('../components/modulos/mercadoria/tabelas/tipos/TipoTable.vue')
  },{
      path: 'tabelas/tipo/criar',
      component: () => import('../components/modulos/mercadoria/tabelas/tipos/Tipo.vue'),
      name: 'tipoCriar'
  },{
      path: 'tabelas/tipo/:id',
      component: () => import('../components/modulos/mercadoria/tabelas/tipos/Tipo.vue'),
      name: 'tipoEditar'
  },{
      path: 'auxiliar/implantar',
      component: () => import('../components/modulos/mercadoria/auxiliar/ImplantarTable.vue')
  },{
      path: 'parametro/:id',
      component: () => import('../components/modulos/mercadoria/Parametro.vue'),
      name: 'MercadoriaParametroEditar'
  },{
      path: 'parametro',
      component: () => import('../components/modulos/mercadoria/ParametroTable.vue'),
      name: 'MercadoriaParametroTabela'
  }]
}]
