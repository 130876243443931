// import { createStore } from 'vuex'

// import auth from './modulos/auth/auth' => PINIA
// import empresas from './modulos/empresas' => PINIA
// import notifications from './modulos/notifications' => PINIA
// import gerenciamento from './modulos/gerenciamento'


// import servico from './modulos/servico/servico'
// import mercadoria from './modulos/mercadoria/mercadoria'
// import visita from './modulos/visita/visita'
// import academia from './modulos/academia/academia' => PINIA
// import provedor from './modulos/provedor/provedor'
// import hotel from './modulos/hotel/hotel'
// import imovel from './modulos/imovel/imovel'
// import faturamento from './modulos/faturamento/faturamento'
// import fiscal from './modulos/fiscal/fiscal'
// import financeiro from './modulos/financeiro/financeiro'
// import contabil from './modulos/contabil/contabil'
// import crm from './modulos/crm/crm'
// import utilitario from './modulos/utilitario/utilitario' 
// import plus from './modulos/plus/plus'
// import publicacao from './modulos/publicacao/publicacao'
// import agenda from './modulos/agenda/agenda'
// import dispositivos from './modulos/dispositivo/dispositivo'
// import turismo from './modulos/turismo/turismo'
// import pdv from './modulos/pdv/pdv'
// import fazenda from './modulos/fazenda/fazenda'
// import tag from './modulos/tag/tag'
// import erp from './modulos/erp/erp'

// //Vue.use(Vuex)

// const debug = process.env.NODE_ENV !== 'production'

// const store = createStore({
//     modules: {
//         //auth,
//         //empresas,
//         //notifications,
//         gerenciamento,
//         servico,
//         mercadoria,
//         visita,
//         //academia,
//         provedor,
//         hotel,
//         imovel,
//         faturamento,
//         fiscal,
//         financeiro,
//         contabil,
//         crm,
//         utilitario, 
//         plus,
//         publicacao,
//         agenda,
//         dispositivos,
//         turismo,
//         pdv,
//         fazenda,
//         tag,
//         erp,        
//     },
//     strict: debug,
// })

// export default store



// src/stores/index.js

import { store } from 'quasar/wrappers'
import { createPinia } from 'pinia'

/*
 * If not building with SSR mode, you can
 * directly export the Store instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Store instance.
 */

export default store((/* { ssrContext } */) => {
  const pinia = createPinia()

  // You can add Pinia plugins here
  // pinia.use(SomePiniaPlugin)

  return pinia
})
