import { boot } from 'quasar/wrappers'
import { Notify } from 'quasar'
import axios from 'axios'
import { useAuthorizationStore } from '../stores/modulos/auth/authorization.js'

const server = axios.create({ 
  baseURL: import.meta.env.API_URL,
  headers: {
    "Content-Type": "application/json",
   }
 })

server.defaults.withCredentials = true
axios.defaults.withXSRFToken = true;

//const token = window.localStorage.getItem('token_sanctum')

function getLocalRefreshToken() {
  const refreshToken = window.localStorage.getItem("refreshToken");
  return refreshToken;
}

export default boot(({ app }) => {
  app.config.globalProperties.$axios = axios
  app.config.globalProperties.$server = server
})

server.interceptors.request.use(
  async (config) => {
    try{
      const storeAuth = useAuthorizationStore();
      const token = storeAuth.token_sanctum || getLocalAccessTolen();
      if(token){
        config.headers['Authorization'] = `Bearer ${ token }`;
        config.headers['x-empresa-header'] = localStorage.getItem('uuid');
      }
    } catch (error){
      console.error('Erro ao configurar interceptador de solicitação: ' ,  error);
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
)

server.interceptors.response.use(
  (response) => {
    return response;
  }, 
  (error) => {
    if (error.response) {
      handleErrorResponse(error);
    }
    return Promise.reject(error);
});

function handleErrorResponse(error){
  const { response } = error;
  switch(response.status){
    case 401:
      Notify.create({
        position: 'center',
        type: 'negative',
        message: 'Sua sessão expirou. Você será direcionado para a página de entrada.',
        caption: error.message
      })
      clearLocalStorage();
      window.open('/','_self');
    break;
    default:
      Notify.create({
        position: 'center',
        type: 'negative',
        message: response.data.message,
        caption: error.message
      })
  }
}

function getLocalAccessTolen() {
  return window.localStorage.getItem("token_sanctum");
}

function clearLocalStorage() {
  localStorage.removeItem('token_sanctum');
  localStorage.removeItem('user');
  localStorage.removeItem('uuid');
  localStorage.removeItem('fantasia');
}

export { server }