import { boot } from 'quasar/wrappers'

import { initializeApp } from 'firebase/app';
//import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { onBackgroundMessage } from "firebase/messaging/sw";

var firebaseConfig = {
    apiKey: import.meta.env.VITE_FIREBASE_API_KEY, 
    authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN, 
    projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
    storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
    appId: import.meta.env.VITE_FIREBASE_APP_ID,
    measurementId: import.meta.env.VITE_FIREBASE_MEASURING_ID,
};

const firebaseApp = initializeApp(firebaseConfig);

//const analytics = getAnalytics(app);
const firebaseMessaging = getMessaging(firebaseApp);



onMessage(firebaseMessaging, (payload) => {
  console.debug('Mensagem recebida. ', payload);
  // ...
});


// export default boot(({app})=> {
//     app.config.globalProperties.$firebase = $firebaseApp
// })

export { firebaseApp, firebaseMessaging, getToken };