const LayoutErp = () => import('../layout/LayoutErp.vue');

export default [{
  path: '/perfil',
  component: import('../layout/LayoutPrincipal.vue'),
    meta: {
        forAuth : true,
    },
  children: [
      { path: 'usuario', component: () => import('../layout/Perfil.vue')}
  ]
}]
