const LayoutContabilidade = () => import('../layout/LayoutContabilidade.vue');

export default [{
  path: '/contabilidade',
  component: LayoutContabilidade,
    meta: {
        forAuth : true,
    },
  children: [{
    path: 'gerais',
      children: [{
          path: 'pessoas',
          component: () => import('../components/modulos/utilitarios/pessoas/PessoasTable.vue')
      }]
  },{
      path: 'parametro/:id',
      component: () => import('../components/modulos/contabilidade/Parametro.vue'),
      name: 'ContabilidadeParametroEditar'
  },{
      path: 'parametro',
      component: () => import('../components/modulos/contabilidade/ParametroTable.vue'),
      name: 'ContabilidadeParametroTabela'
  }]
}]
