const LayoutCrm = () => import('../layout/LayoutCrm.vue');

export default [{
  path: '/crm',
  component: LayoutCrm,
    meta: {
        forAuth : true,
    },
  children: [{
    path: 'gerais',
      children: [{
          path: 'pessoas',
          component: () => import('../components/modulos/utilitarios/pessoas/PessoasTable.vue')
      }]
  },{
      path: 'parametro/:id',
      component: () => import('../components/modulos/crm/Parametro.vue'),
      name: 'CrmParametroEditar'
  },{
      path: 'parametro',
      component: () => import('../components/modulos/crm/ParametroTable.vue'),
      name: 'CrmParametroTabela'
  }]
}]
