const LayoutVisitas = () => import('../layout/LayoutVisitas.vue');

export default [{
  path: '/visitas',
  component: LayoutVisitas,
    meta: {
        forAuth : true,
    },
  children: [{
      path: 'visitas',
      component: () => import('../components/modulos/visita/VisitasTable.vue'),
      name: 'visitas'
  },{
    path: 'visitas/:id/listar',
    component: () => import('../components/modulos/visita/VisitasTable.vue'),
    name: 'visitasListar'
  },{
      path: 'visitas/:id',
      component: () => import('../components/modulos/visita/Visitas.vue'),
      name: 'visitaEditar'
  },{
    path: 'visitas/criar',
    component: () => import('../components/modulos/visita/Visitas.vue'),
    name: 'visitaCriar'
  },{
      path: 'tipos',
      component: () => import('../components/modulos/visita/TiposTable.vue'),
      name: 'visita.tipos'
  },{
      path: 'tipos/:id',
      component: () => import('../components/modulos/visita/Tipos.vue'),
      name: 'visita.tipos.Editar'
  },{
      path: 'parametro/:id',
      component: () => import('../components/modulos/visita/Parametro.vue'),
      name: 'VisitaParametroEditar'
  },{
      path: 'parametro',
      component: () => import('../components/modulos/visita/ParametroTable.vue'),
      name: 'VisitaParametroTabela'
  }]
}]
