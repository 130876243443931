const LayoutPdv = () => import('../layout/LayoutPdv.vue');

export default [{
  path: '/pdv',
  component: LayoutPdv,
    meta: {
        forAuth : true,
    },
  children: [{
    path: 'pdv1111',
      children: [{
          path: 'pessoas',
          component: () => import('../components/modulos/pdv/Pdv.vue')
      }]
  },{
      path: 'dashboard',
      component: () => import('../components/modulos/pdv/Dashboard.vue')
  },{
      path: 'pdv',
      component: () => import('../components/modulos/pdv/Pdv.vue')
  },{
      path: 'mesas',
      component: () => import('../components/modulos/pdv/Mesas.vue')
  },{
      path: 'caixa',
      component: () => import('../components/modulos/pdv/Caixa.vue')
  },{
      path: 'parametro/:id',
      component: () => import('../components/modulos/pdv/Parametro.vue'),
      name: 'PdvParametroEditar'
  },{
      path: 'parametro',
      component: () => import('../components/modulos/pdv/ParametroTable.vue'),
      name: 'PdvParametroTabela'
  }]
}]
