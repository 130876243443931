const LayoutGerenciamento = () => import('../layout/LayoutGerenciamento.vue');

export default [{
  path: '/gerenciamento',
  component: LayoutGerenciamento,
    meta: {
        forAuth : true,
    },
  children: [{
      path: 'estrutura/sql',
      component: () => import('../components/modulos/gerenciamento/estrutura/FormSQL.vue')
  },{
      path: 'estrutura/enviar',
      component: () => import('../components/modulos/gerenciamento/estrutura/TableEnviar.vue')
  },{
      path: 'estrutura/tabelas',
      component: () => import('../components/modulos/gerenciamento/estrutura/TabelasTable.vue')
  },{
      path: 'estrutura/compara',
      component: () => import('../components/modulos/gerenciamento/estrutura/Compara.vue')
  },{
      path: 'mercadorias/tabelas',
      component: () => import('../components/modulos/gerenciamento/mercadorias/TableTabelasFornecedores.vue')
  },{
      path: 'mercadorias/tabelas/criar',
      component: () => import('../components/modulos/gerenciamento/mercadorias/TabelasFornecedores.vue'),
      name: 'GerenciamentoMercadoriasTabelasCriar'
  },{
      path: 'mercadorias/tabelas/:id',
      component: () => import('../components/modulos/gerenciamento/mercadorias/TabelasFornecedores.vue'),
      name: 'GerenciamentoMercadoriasTabelasEditar'
  },{
      path: 'fazenda/categoria',
      component: () => import('../components/modulos/gerenciamento/fazenda/CategoriaTable.vue')
  },{
      path: 'fazenda/genero',
      component: () => import('../components/modulos/gerenciamento/fazenda/GeneroTable.vue')
  },{
      path: 'fazenda/importar',
      component: () => import('../components/modulos/gerenciamento/fazenda/Importar.vue')
  },{
      path: 'fazenda/raca',
      component: () => import('../components/modulos/gerenciamento/fazenda/RacaTable.vue')
  },{
      path: 'fazenda/situacao',
      component: () => import('../components/modulos/gerenciamento/fazenda/SituacaoTable.vue')
  },{
      path: 'financeiro/forma',
      component: () => import('../components/modulos/gerenciamento/financeiro/FormaTable.vue')
  },{
      path: 'usuarios',
      component: () => import('../components/modulos/gerenciamento/UsuariosTable.vue')
  },{
      path: 'servidor/laravel/filas',
      component: () => import('../components/modulos/gerenciamento/servidor/laravel/TableFilas.vue')
  },{
    path: 'servidor/firebase',
    component: () => import('../components/modulos/gerenciamento/servidor/Firebase.vue')
  },{
      path: 'tabelas/uf',
      component: () => import('../components/modulos/gerenciamento/tabelas/UfTable.vue')
  },{
      path: 'tabelas/municipios',
      component: () => import('../components/modulos/gerenciamento/tabelas/MunicipioTable.vue')
  },{
      path: 'tabelas/usuarios',
      component: () => import('../components/modulos/gerenciamento/tabelas/UsuariosTable.vue')
  },{
      path: 'tabelas/marca/criar',
      component: () => import('../components/modulos/gerenciamento/tabelas/Marca.vue')
  },{
      path: 'tabelas/marca',
      component: () => import('../components/modulos/gerenciamento/tabelas/MarcaTable.vue')
  },{
      path: 'tabelas/modelo/criar',
      component: () => import('../components/modulos/gerenciamento/tabelas/Modelo.vue')
  },{
      path: 'tabelas/modelo',
      component: () => import('../components/modulos/gerenciamento/tabelas/ModeloTable.vue')
  },{
      path: 'tabelas/versao/criar',
      component: () => import('../components/modulos/gerenciamento/tabelas/Versao.vue')
  },{
      path: 'tabelas/versao',
      component: () => import('../components/modulos/gerenciamento/tabelas/VersaoTable.vue')
  },{
      path: 'tabelas/cor/criar',
      component: () => import('../components/modulos/gerenciamento/tabelas/Cor.vue')
  },{
      path: 'tabelas/cor',
      component: () => import('../components/modulos/gerenciamento/tabelas/CorTable.vue')
  },{
      path: 'tabelas/modulos',
      component: () => import('../components/modulos/gerenciamento/tabelas/ModulosTable.vue')
  },{
      path: 'tabelas/areasatuacao',
      component: () => import('../components/modulos/gerenciamento/tabelas/AreasAtuacaoTable.vue')
  },{
      path: 'tabelas/areasatuacao/:id',
      component: () => import('../components/modulos/gerenciamento/tabelas/AreasAtuacao.vue'),
      name: 'areasatuacaoEditar'
  }]
}]