const LayoutPlus = () => import('../layout/LayoutPlus.vue');

export default [{
  path: '/plus',
  component: LayoutPlus,
    meta: {
        forAuth : true,
    },
  children: [

      { path: 'inventario/chassi', component: () => import('../components/modulos/plus/inventario/Chassi.vue')},
      { path: 'inventario/produto', component: () => import('../components/modulos/plus/inventario/Produto.vue')},

      { path: 'tabelas/revenda', component: () => import('../components/modulos/plus/tabelas/TableRevenda.vue')},
      { path: 'tabelas/patio', component: () => import('../components/modulos/plus/tabelas/TablePatio.vue')},
      { path: 'tabelas/rubrica', component: () => import('../components/modulos/plus/tabelas/TableRubrica.vue')},
      { path: 'tabelas/custo', component: () => import('../components/modulos/plus/tabelas/TableCusto.vue')},
      { path: 'tabelas/veiculo', component: () => import('../components/modulos/plus/tabelas/TableVeiculo.vue')},
      { path: 'tabelas/hierarquia', component: () => import('../components/modulos/plus/tabelas/TableHierarquia.vue')},
      { path: 'tabelas/funcionario', component: () => import('../components/modulos/plus/tabelas/TableFuncionario.vue')},
      { path: 'tabelas/indicador', component: () => import('../components/modulos/plus/tabelas/TableIndicador.vue')},
      { path: 'tabelas/perfil', component: () => import('../components/modulos/plus/tabelas/TablePerfil.vue')},
      { path: 'tabelas/portador', component: () => import('../components/modulos/plus/tabelas/TablePortador.vue')},
      { path: 'tabelas/produto', component: () => import('../components/modulos/plus/tabelas/TableProduto.vue')},
  ]
}]
