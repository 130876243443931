import '@/assets/tailwind.css'
import 'quasar/src/css/index.sass'

import * as Sentry from "@sentry/vue";

const app = createApp(defineAsyncComponent(() => import('./App.vue')));

import pkg from '../../../package.json';

import { createApp, defineAsyncComponent } from 'vue'
//import App from './App.vue'
import { createPinia } from 'pinia'

import './boot/axios.js'
import { VueApexCharts } from './boot/apexcharts.js';
import { firebaseApp, firebaseMessaging } from './boot/firebase.js'

import { Quasar, Notify } from 'quasar'
import quasarLang from 'quasar/lang/pt-BR'
import quasarIconSet from 'quasar/icon-set/mdi-v6'
// Import icon libraries
import '@quasar/extras/mdi-v6/mdi-v6.css'

import router from './router/index.js'
import store from './stores/index.js'

// tem que mudar para o VUE 3
Sentry.init({
  app,
  release: "carnauba@" + pkg.version,
  environment: import.meta.env.VITE_APP_ENV,
  dsn: "https://d149a5d2ad7143a8a45421411efbc081@o504300.ingest.sentry.io/5639120",
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router)
    })
  ],
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  tracesSampleRate: 1.0,
  debug: true
});


// import i18n from "./i18n";
// //import Snotify from 'vue-snotify'
// import Toasted from 'vue-toasted'
// import VueCurrencyInput from 'vue-currency-input'
// import VueLodash from 'vue-lodash'
// import lodash from 'lodash'

import globals from './globals.js'
// import Popper from 'popper.js'
// import VueGtag from 'vue-gtag'

// import * as layout from "./components/layout";
// import * as ui from "./components/ui";
// import * as guessers from "./components/guessers";

/**
  * Register Admin UI components
  */
// [layout, ui, guessers].forEach((c) => {
//   Object.keys(c).forEach((name) => {
//     Vue.component(`Va${name}`, c[name]);
//   });
// });


//import bugsnag from '@bugsnag/js'
//import bugsnagVue from '@bugsnag/plugin-vue'


//const bugsnagClient = bugsnag.start({ 
//  apiKey: '17bd56bb2860bd90e3e52a9089b3c203', 
//  releaseStage: process.env.MIX_APP_ENV 
//})

//bugsnagClient(bugsnagVue, Vue)

// Required to enable animations on dropdowns/tooltips/popovers
// Popper.Defaults.modifiers.computeStyle.gpuAcceleration = false

// Vue.config.productionTip = false

/**
 * Specific badge color status function
 */
//  Vue.prototype.$statusColor = (s) => {
//   const colors = {
//     published: "success",
//     pending: "warning",
//     denied: "error",
//   };

//   return colors[s];
// };


// Vue.use(Snotify, {toast: {showProgressBar: false}})
// Vue.use(Toasted)

// Vue.use(VueCurrencyInput)
// Vue.use(VueLodash,{name:'custom',lodash:lodash})

// Vue.component('sabidos-table', require('./components/componentes/table/SabidosTable.vue').default);
// Vue.component('sabidos-titulo', require('./components/componentes/SabidosTitulo.vue').default);

const AreaFavoritos = () => import('./components/miscellaneous/AreaFavoritos.vue')
//import SabidosTitulo from './components/componentes/SabidosTitulo.vue'
//import SabidosTable from './components/componentes/table/SabidosTable.vue'


//Vue.use(App)

// Global RTL flag
// Vue.mixin({
//   data: globals
// })

/* eslint-disable no-new */

// new Vue({
//   el: '#app',
//   store,
//   router,
//   template: '<App/>',
//   components: { App }
// }).$mount('#app')

// Vue.use(VueGtag, {
//   config:{id: 'UA-127300448-1'}
// });



export const pinia = createPinia()

app.use(pinia)
app.use(store)
app.use(router)
app.use(VueApexCharts)
app.use(firebaseApp)
app.use(firebaseMessaging)
app.use(globals)
app.use(Quasar, {
  plugins: {
    Notify
  },
  config: {
    notify: { /* look at QuasarConfOptions from the API card */ }
  },
  iconSet: quasarIconSet,
  lang: quasarLang,
})
app.component('AreaFavoritos', AreaFavoritos)
//app.component('SabidosTable', SabidosTable)
//app.component('SabidosTitulo', SabidosTitulo)
app.mount('#app')