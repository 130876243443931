const LayoutUtilitarios = () => import('../layout/LayoutUtilitarios.vue');

export default [{
  path: '/utilitarios',
  component: LayoutUtilitarios,
  meta: {
        forAuth : true,
    },
    children: [
    {
        path: 'ajuda',
        component: () => import('../components/modulos/Ajuda.vue'),
        name: 'utilitarios.ajuda'
    },{
        path: 'tabelas/pessoas/:id/listar',
        component: () => import('../components/modulos/utilitarios/pessoas/PessoasTable.vue'),
        name: 'pessoasListar'
    },{
        path: 'tabelas/pessoas',
        component: () => import('../components/modulos/utilitarios/pessoas/PessoasTable.vue'),
        name: 'utilitarios.pessoas'
    },{
        path: 'tabelas/pessoas/criar',
        component: () => import('../components/modulos/utilitarios/pessoas/Pessoas.vue'),
        name: 'pessoasCriar'
    },{
        path: 'tabelas/pessoas/:id',
        component: () => import('../components/modulos/utilitarios/pessoas/Pessoas.vue'),
        name: 'pessoasEditar'
    },{
        path: 'tabelas/empresas',
        component: () => import('../components/modulos/utilitarios/empresas/EmpresasTable.vue')
    },{
        path: 'tabelas/empresas/criar',
        component: () => import('../components/modulos/utilitarios/empresas/Empresas.vue'),
        name: 'empresasCriar'
    },{
        path: 'tabelas/empresas/:id/listar',
        component: () => import('../components/modulos/utilitarios/empresas/EmpresasTable.vue'),
        name: 'empresasListar'
    },{
        path: 'tabela/departamento',
        component: () => import('../components/modulos/utilitarios/departamento/DepartamentoTable.vue')
    },{
        path: 'tabela/departamento/criar',
        component: () => import('../components/modulos/utilitarios/departamento/Departamento.vue'),
        name: 'departamentoCriar'
    },{
        path: 'tabelas/departamento/:id/listar',
        component: () => import('../components/modulos/utilitarios/departamento/DepartamentoTable.vue'),
        name: 'departamentoListar'
    },{
        path: 'tabela/departamento/:id',
        component: () => import('../components/modulos/utilitarios/departamento/Departamento.vue'),
        name: 'departamentoEditar'
    },{
        path: 'tabela/tipoanexo',
        component: () => import('../components/modulos/utilitarios/tipoanexo/List.vue')
    },{
        path: 'parametros/:id',
        component: () => import('../components/modulos/utilitarios/Parametro.vue'),
        name: 'UtilitarioParametroEditar'
    },{
        path: 'parametros',
        component: () => import('../components/modulos/utilitarios/ParametroTable.vue'),
        name: 'UtilitarioParametroTabela'
    }
  ]
}]
