//window.axios = require('axios');

import axios from 'axios';

window.axios = axios;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

window.axios.defaults.withCredentials = true;
window.axios.defaults.withXSRFToken = true;

/*
this.$echo.channel('carnauba_database_utilpessoas-created').listen('UtilPessoasCreated', (e) => {
  
  //Bus.$emit('post.created', e.post)

  //Vue.$vToastify.success(`Título do post ${e.post.name}`, 'Novo Post')
});
*/

let token = document.head.querySelector('meta[name="csrf-token"]').getAttribute('content');

if (token) {
  window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token;
} else {
  console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}

/**
 * Load Vue.js app
 */

import './main.js';
