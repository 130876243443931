const LayoutPrincipal = () => import('../layout/LayoutPrincipal.vue');

export default [{
  path: '/ajuda',
  component: LayoutPrincipal,
    meta: {
        forAuth : true,
    },
  children: [{
    path: '/',
      children: [{
          path: 'pessoas',
          component: () => import('../layout/Ajuda.vue')
      }]
  }]
}]
