const LayoutPrincipal = () => import('../layout/LayoutPrincipal.vue');

export default [{
  path: '/principal',
  name: 'principal',
  component: LayoutPrincipal,
  meta: {
      forAuth : true,
  },
  children: [{
    path: 'gerais',
      children: [{
          path: 'pessoas',
          component: () => import('../components/modulos/utilitarios/pessoas/PessoasTable.vue')
      }]
  }]
}]
