const LayoutTag = () => import('../layout/LayoutTag.vue');

export default [{
    path: '/tag',
    component: LayoutTag,
    meta: {
        forAuth : true,
    },
    children: [
    {
        path: 'dashboard',
        component: () => import('../components/modulos/tag/Dashboard.vue'),
        name: 'tagDashboard'
    },{
        path: 'tag',
        component: () => import('../components/modulos/tag/TagTable.vue'),
        name: 'tagTabela'
    },{
        path: 'tag/criar',
        component: () => import('../components/modulos/tag/Tag.vue'),
        name: 'tagCriar'
    },{
        path: 'tag/:id',
        component: () => import('../components/modulos/tag/Tag.vue'),
        name: 'tagVisualizar'
    },{
        path: 'tag/:id/editar',
        component: () => import('../components/modulos/tag/Tag.vue'),
        name: 'tagEditar'
    },{
        path: 'parametro/:id',
        component: () => import('../components/modulos/tag/Parametro.vue'),
        name: 'tagParametroEditar'
    },{
        path: 'parametro',
        component: () => import('../components/modulos/tag/ParametroTable.vue'),
        name: 'tagParametroTabela'
    }]
}]