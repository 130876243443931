const LayoutFaturamento = () => import('../layout/LayoutFaturamento.vue');

export default [{
  path: '/faturamento',
  component: LayoutFaturamento,
    meta: {
        forAuth : true,
    },
  children: [{
    path: 'gerais',
      children: [{
          path: 'pessoas',
          component: () => import('../components/modulos/utilitarios/pessoas/PessoasTable.vue')
      }]
  },{
      path: 'documento',
      component: () => import('../components/modulos/faturamento/DocumentoTable.vue'),
      name: 'FaturamentoDocumento'
  },{
    path: 'serie',
    component: () => import('../components/modulos/faturamento/SerieTable.vue'),
    name: 'FaturamentoSerie'
  },{
      path: 'parametro/:id',
      component: () => import('../components/modulos/faturamento/Parametro.vue'),
      name: 'FaturamentoParametroEditar'
  },{
      path: 'parametro',
      component: () => import('../components/modulos/faturamento/ParametroTable.vue'),
      name: 'FaturamentoParametroTabela'
  }]
}]
