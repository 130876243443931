//import loginPage from '../../components/auth/Login.vue';
const LayoutBlank = () => import('../../layout/LayoutBlank.vue');

const LoginPage = () => import('../../components/auth/Login.vue');
const LogoutPage = () => import('../../components/auth/Logout.vue');
const ResetPage = () => import('../../components/auth/PasswordReset.vue');
const ChangePage = () => import('../../components/auth/PasswordChange.vue');

export default [{
  path: '/login',
  component: LayoutBlank,
  children: [
      {path: '', name: 'login', component: LoginPage, meta: { forVisitors: true }},
      {path: '/logout', name: 'logout', component: LogoutPage, meta: { forAuth : true }},
      {path: '/passwordreset', name: 'passwordreset', component: () => import('../../components/auth/PasswordReset.vue')},
      {path: '/passwordchange/:token', name: 'passwordchange', component: () => import('../../components/auth/PasswordChange.vue')}
  ]
}]
