const LayoutServicos = () => import('../layout/LayoutServicos.vue');

export default [{
  path: '/servico',
  component: LayoutServicos,
    meta: {
        forAuth : true,
    },
  children: [{
      path: 'orcamento',
      component: () => import('../components/modulos/servico/OrcamentoTable.vue')
  },{
      path: 'orcamento/criar',
      component: () => import('../components/modulos/servico/Orcamento.vue'),
      name: 'orcamentoServicoCriar'
  },{
      path: 'orcamento/:id/editar',
      component: () => import('../components/modulos/servico/Orcamento.vue'),
      name: 'orcamentoServicoEditar'
  },{
      path: 'orcamento/:id/visualizar',
      component: () => import('../components/modulos/servico/Orcamento.vue'),
      name: 'orcamentoServicoVisualizar'
  },{
      path: 'ordem',
      component: () => import('../components/modulos/servico/OrdemTable.vue')
  },{
      path: 'ordem/criar',
      component: () => import('../components/modulos/servico/Ordem.vue'),
      name: 'ordemCriar'
  },{
      path: 'ordem/:id/editar',
      component: () => import('../components/modulos/servico/Ordem.vue'),
      name: 'ordemEditar'
  },{
      path: 'ordem/:id/visualizar',
      component: () => import('../components/modulos/servico/Ordem.vue'),
      name: 'ordemVisualizar'
  },{
      path: 'item',
      component: () => import('../components/modulos/servico/ItemTable.vue')
  },{
      path: 'item/criar',
      component: () => import('../components/modulos/servico/Item.vue'),
      name: 'itemCriar'
  },{
      path: 'item/:id/editar',
      component: () => import('../components/modulos/servico/Item.vue'),
      name: 'itemEditar'
  },{
      path: 'item/:id/visualizar',
      component: () => import('../components/modulos/servico/Item.vue'),
      name: 'itemVisualizar'
  },{
      path: 'tabelas/etiqueta',
      component: () => import('../components/modulos/servico/tabelas/EtiquetaTable.vue'),
      name: 'etiquetaTabela'
  },{
      path: 'tabelas/etiqueta/criar',
      component: () => import('../components/modulos/servico/tabelas/EtiquetaCriar.vue'),
      name: 'etiquetaTabelaCriar'
  },{
      path: 'tabelas/etiqueta/consulta/:chave',
      component: () => import('../components/modulos/servico/tabelas/EtiquetaConsulta.vue'),
      name: 'etiquetaTabelaConsulta'
  },{
      path: 'parametro/:id',
      component: () => import('../components/modulos/servico/Parametro.vue'),
      name: 'ServicoParametroEditar'
  },{
      path: 'parametro',
      component: () => import('../components/modulos/servico/ParametroTable.vue'),
      name: 'ServicoParametroTabela'
  }]
}]
