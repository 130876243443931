const LayoutImoveis = () => import('../layout/LayoutImoveis.vue');

export default [{
  path: '/imovel',
  component: LayoutImoveis,
    meta: {
        forAuth : true,
    },
  children: [{
    path: 'gerais',
      children: [{
          path: 'pessoas',
          component: () => import('../components/modulos/utilitarios/pessoas/PessoasTable.vue')
      }]
  },{
    path: 'tabela/imovel',
    component: () => import('../components/modulos/imovel/ImovelTable.vue'),
    name: 'imovel'
},{
  path: 'tabela/imovel/:id/listar',
  component: () => import('../components/modulos/imovel/ImovelTable.vue'),
  name: 'tabela/imovelListar'
},{
    path: 'tabela/imovel/criar',
    component: () => import('../components/modulos/imovel/Imovel.vue'),
    name: 'imovelCriar'
},{
    path: 'tabela/imovel/:id',
    component: () => import('../components/modulos/imovel/Imovel.vue'),
    name: 'imovelEditar'
},{
      path: 'parametro/:id',
      component: () => import('../components/modulos/imovel/Parametro.vue'),
      name: 'ImoveisParametroEditar'
  },{
      path: 'parametro',
      component: () => import('../components/modulos/imovel/ParametroTable.vue'),
      name: 'ImovelParametroTabela'
  }]
}]
