const LayoutTurismo = () => import('../layout/LayoutTurismo.vue');

export default [{
  path: '/turismo',
  component: LayoutTurismo,
    meta: {
        forAuth : true,
    },
  children: [
    {
        path: 'ajuda',
        component: () => import('../components/modulos/Ajuda.vue'),
        name: 'turismo.ajuda'
    },{
    path: 'tabelas',
      children: [{
          path: 'passeios',
          component: () => import('../components/modulos/utilitarios/pessoas/PessoasTable.vue')
      }]
  },{
      path: 'reserva/criar',
      component: () => import('../components/modulos/turismo/Reserva.vue')
  },{
      path: 'reserva',
      component: () => import('../components/modulos/turismo/ReservaTable.vue')
  },{
      path: 'parametro/:id',
      component: () => import('../components/modulos/turismo/Parametro.vue'),
      name: 'TurismoParametroEditar'
  },{
      path: 'parametro',
      component: () => import('../components/modulos/turismo/ParametroTable.vue'),
      name: 'TurismoParametroTabela'
  }
    ]
}]
