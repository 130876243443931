const LayoutErp = () => import('../layout/LayoutErp.vue');

export default [{
  path: '/erp',
  component: LayoutErp,
    meta: {
        forAuth : true,
    },
  children: [
      //{ path: 'adicion-veiculos-estoque', component: () => import('../components/modulos/erp/adicion/veiculos/estoque/TableEstoque')},
      { path: 'adicion/veiculos/estoque', component: () => import('../components/modulos/erp/adicion/veiculos/estoque/TableEstoque.vue')},
      { path: 'adicion/veiculos/estoque/:filial/:ficha', component: () => import('../components/modulos/erp/adicion/veiculos/estoque/Estoque.vue')},

      //{ path: 'adicion-veiculos-relatorios-compras', component: () => import('../components/modulos/erp/adicion/veiculos/relatorios/Compras')},
      //{ path: 'adicion-veiculos-relatorios-estoque', component: () => import('../components/modulos/erp/adicion/veiculos/relatorios/Estoque')},
      //{ path: 'adicion-veiculos-relatorios-vendas',  component: () => import('../components/modulos/erp/adicion/veiculos/relatorios/Vendas')},

      { path: 'adicion/veiculos/relatorios/compras', component: () => import('../components/modulos/erp/adicion/veiculos/relatorios/Compras.vue')},
      { path: 'adicion/veiculos/relatorios/estoque', component: () => import('../components/modulos/erp/adicion/veiculos/relatorios/Estoque.vue')},
      { path: 'adicion/veiculos/relatorios/vendas',  component: () => import('../components/modulos/erp/adicion/veiculos/relatorios/Vendas.vue')},

      { path: 'adicion/veiculos/tabelas/cor', component: () => import('../components/modulos/erp/adicion/veiculos/tabelas/CorTable.vue')},
      { path: 'adicion/veiculos/tabelas/modelos', component: () => import('../components/modulos/erp/adicion/veiculos/tabelas/ModelosTable.vue')},


      //{ path: 'adicion-consorcio-contratos', component: () => import('../components/modulos/erp/adicion/consorcio/TableContratos')},
      //{ path: 'adicion/consorcio/contrato/:filial/:contrato', component: () => import('../components/modulos/erp/adicion/consorcio/Contratos')},
      //{ path: 'adicion-consorcio-relatorios-contratos', component: () => import('../components/modulos/erp/adicion/consorcio/relatorios/Contratos')},
      //{ path: 'adicion-consorcio-relatorios-estoque', component: () => import('../components/modulos/erp/adicion/consorcio/relatorios/Estoque')},

      { path: 'adicion/consorcio/contratos', component: () => import('../components/modulos/erp/adicion/consorcio/TableContratos.vue')},
      { path: 'adicion/consorcio/contrato/:filial/:contrato', component: () => import('../components/modulos/erp/adicion/consorcio/Contratos.vue')},
      { path: 'adicion/consorcio/relatorios/contratos', component: () => import('../components/modulos/erp/adicion/consorcio/relatorios/Contratos.vue')},
      { path: 'adicion/consorcio/relatorios/estoque', component: () => import('../components/modulos/erp/adicion/consorcio/relatorios/Estoque.vue')},

      //{ path: 'adicion-oficina-ordens', component: () => import('../components/modulos/erp/adicion/oficina/TableOS')},
      //{ path: 'adicion/oficina/ordem/:filial/:ordem', component: () => import('../components/modulos/erp/adicion/oficina/Ordem')},
      //{ path: 'adicion-oficina-relatorios-ordens', component: () => import('../components/modulos/erp/adicion/oficina/relatorios/OS')},
      //{ path: 'adicion-oficina-relatorios-vendas', component: () => import('../components/modulos/erp/adicion/oficina/relatorios/Vendas')},

      { path: 'adicion/oficina/ordens', component: () => import('../components/modulos/erp/adicion/oficina/TableOS.vue')},
      { path: 'adicion/oficina/ordem/:filial/:ordem', component: () => import('../components/modulos/erp/adicion/oficina/Ordem.vue')},
      { path: 'adicion/oficina/relatorios/ordens', component: () => import('../components/modulos/erp/adicion/oficina/relatorios/OS.vue')},
      { path: 'adicion/oficina/relatorios/vendas', component: () => import('../components/modulos/erp/adicion/oficina/relatorios/Vendas.vue')},

      //{ path: 'adicion-pecas-requisicoes', component: () => import('../components/modulos/erp/adicion/pecas/TableRequisicoes')},
      //{ path: 'adicion/pecas/requisicao/:filial/:requisicao', component: () => import('../components/modulos/erp/adicion/pecas/Requisicao')},
      //{ path: 'adicion-pecas-relatorios-compras', component: () => import('../components/modulos/erp/adicion/pecas/relatorios/Compras')},
      //{ path: 'adicion-pecas-relatorios-requisicoes', component: () => import('../components/modulos/erp/adicion/pecas/relatorios/Requisicoes')},
      //{ path: 'adicion-pecas-relatorios-inventario', component: () => import('../components/modulos/erp/adicion/pecas/relatorios/Inventario')},

      { path: 'adicion/pecas/requisicoes', component: () => import('../components/modulos/erp/adicion/pecas/TableRequisicoes.vue')},
      { path: 'adicion/pecas/requisicao/:filial/:requisicao', component: () => import('../components/modulos/erp/adicion/pecas/Requisicao.vue')},
      { path: 'adicion/pecas/relatorios/compras', component: () => import('../components/modulos/erp/adicion/pecas/relatorios/Compras.vue')},
      { path: 'adicion/pecas/relatorios/requisicoes', component: () => import('../components/modulos/erp/adicion/pecas/relatorios/Requisicoes.vue')},
      { path: 'adicion/pecas/relatorios/inventario', component: () => import('../components/modulos/erp/adicion/pecas/relatorios/Inventario.vue')},
/*
      { path: 'adicion-financeiro-receber-duplicatas', component: () => import('../components/modulos/erp/adicion/financeiro/receber/TableDuplicatas')},
      { path: 'adicion/financeiro/receber/duplicatas/:filial/:duplicata/:ordem/:cliente', component: () => import('../components/modulos/erp/adicion/financeiro/receber/Duplicata')},
      { path: 'adicion-financeiro-receber-relatorios-duplicatas', component: () => import('../components/modulos/erp/adicion/financeiro/receber/relatorios/Duplicatas')},
      { path: 'adicion/financeiro/receber/relatorios/duplicatas/data', component: () => import('../components/modulos/erp/adicion/financeiro/receber/relatorios/DuplicatasGrid')},
      { path: 'adicion-financeiro-receber-relatorios-antecipacoes', component: () => import('../components/modulos/erp/adicion/financeiro/receber/relatorios/Antecipacoes')},
      { path: 'adicion-financeiro-receber-relatorios-cartoes', component: () => import('../components/modulos/erp/adicion/financeiro/receber/relatorios/Cartoes')},
      { path: 'adicion-financeiro-receber-relatorios-cheques', component: () => import('../components/modulos/erp/adicion/financeiro/receber/relatorios/Cheques')},
      { path: 'adicion-financeiro-receber-relatorios-vales', component: () => import('../components/modulos/erp/adicion/financeiro/receber/relatorios/Vales')},
      { path: 'adicion-financeiro-pagar-duplicatas', component: () => import('../components/modulos/erp/adicion/financeiro/pagar/TableDuplicatas')},
      { path: 'adicion/financeiro/pagar/duplicatas/:filial/:duplicata/:ordem/:fornecedor', component: () => import('../components/modulos/erp/adicion/financeiro/pagar/Duplicata')},
      { path: 'adicion-financeiro-pagar-relatorios-duplicatas', component: () => import('../components/modulos/erp/adicion/financeiro/pagar/relatorios/Duplicatas')},
      { path: 'adicion-financeiro-pagar-relatorios-despesas', component: () => import('../components/modulos/erp/adicion/financeiro/pagar/relatorios/Despesas')},
      { path: 'adicion-financeiro-pagar-relatorios-adiantamentos', component: () => import('../components/modulos/erp/adicion/financeiro/pagar/relatorios/Adiantamentos')},
      { path: 'adicion-financeiro-relatorios-extrato', component: () => import('../components/modulos/erp/adicion/financeiro/relatorios/Extrato')},
      { path: 'adicion/financeiro/tabelas/chequesdeterceiros', component: () => import('../components/modulos/erp/adicion/financeiro/tabelas/ChequesDeTerceirosTable')},
      { path: 'adicion/financeiro/tabelas/cartoesdecredito', component: () => import('../components/modulos/erp/adicion/financeiro/tabelas/CartoesDeCreditoTable')},
      { path: 'adicion/financeiro/tabelas/adiantamentos', component: () => import('../components/modulos/erp/adicion/financeiro/tabelas/AdiantamentosTable')},
      { path: 'adicion/financeiro/tabelas/antecipacoes', component: () => import('../components/modulos/erp/adicion/financeiro/tabelas/AntecipacoesTable')},

*/

      { path: 'adicion/financeiro/receber/duplicatas', component: () => import('../components/modulos/erp/adicion/financeiro/receber/TableDuplicatas.vue')},
      { path: 'adicion/financeiro/receber/duplicatas/:filial/:duplicata/:ordem/:cliente', component: () => import('../components/modulos/erp/adicion/financeiro/receber/Duplicata.vue')},
      { path: 'adicion/financeiro/receber/relatorios/duplicatas', component: () => import('../components/modulos/erp/adicion/financeiro/receber/relatorios/Duplicatas.vue')},
      { path: 'adicion/financeiro/receber/relatorios/duplicatas/data', component: () => import('../components/modulos/erp/adicion/financeiro/receber/relatorios/DuplicatasGrid.vue')},
      { path: 'adicion/financeiro/receber/relatorios/antecipacoes', component: () => import('../components/modulos/erp/adicion/financeiro/receber/relatorios/Antecipacoes.vue')},
      { path: 'adicion/financeiro/receber/relatorios/cartoes', component: () => import('../components/modulos/erp/adicion/financeiro/receber/relatorios/Cartoes.vue')},
      { path: 'adicion/financeiro/receber/relatorios/cheques', component: () => import('../components/modulos/erp/adicion/financeiro/receber/relatorios/Cheques.vue')},
      { path: 'adicion/financeiro/receber/relatorios/vales', component: () => import('../components/modulos/erp/adicion/financeiro/receber/relatorios/Vales.vue')},
      { path: 'adicion/financeiro/pagar/duplicatas', component: () => import('../components/modulos/erp/adicion/financeiro/pagar/TableDuplicatas.vue')},
      { path: 'adicion/financeiro/pagar/duplicatas/:filial/:duplicata/:ordem/:fornecedor', component: () => import('../components/modulos/erp/adicion/financeiro/pagar/Duplicata.vue')},
      { path: 'adicion/financeiro/pagar/relatorios/duplicatas', component: () => import('../components/modulos/erp/adicion/financeiro/pagar/relatorios/Duplicatas.vue')},
      { path: 'adicion/financeiro/pagar/relatorios/despesas', component: () => import('../components/modulos/erp/adicion/financeiro/pagar/relatorios/Despesas.vue')},
      { path: 'adicion/financeiro/pagar/relatorios/adiantamentos', component: () => import('../components/modulos/erp/adicion/financeiro/pagar/relatorios/Adiantamentos.vue')},
      { path: 'adicion/financeiro/relatorios/extrato', component: () => import('../components/modulos/erp/adicion/financeiro/relatorios/Extrato.vue')},
      { path: 'adicion/financeiro/tabelas/chequesdeterceiros', component: () => import('../components/modulos/erp/adicion/financeiro/tabelas/ChequesDeTerceirosTable.vue')},
      { path: 'adicion/financeiro/tabelas/cartoesdecredito', component: () => import('../components/modulos/erp/adicion/financeiro/tabelas/CartoesDeCreditoTable.vue')},
      { path: 'adicion/financeiro/tabelas/adiantamentos', component: () => import('../components/modulos/erp/adicion/financeiro/tabelas/AdiantamentosTable.vue')},
      { path: 'adicion/financeiro/tabelas/antecipacoes', component: () => import('../components/modulos/erp/adicion/financeiro/tabelas/AntecipacoesTable.vue')},

      { path: 'adicion/financeiro/configuracoes/contas', component: () => import('../components/modulos/erp/adicion/financeiro/configuracoes/TableContas.vue')},

      //{ path: 'adicion-faturamento-gerenciador', component: () => import('../components/modulos/erp/adicion/faturamento/TableGerenciador')},
      //{ path: 'adicion-faturamento-xml', component: () => import('../components/modulos/erp/adicion/faturamento/Xml')},

      { path: 'adicion/faturamento/gerenciador', component: () => import('../components/modulos/erp/adicion/faturamento/TableGerenciador.vue')},
      { path: 'adicion/faturamento/xml', component: () => import('../components/modulos/erp/adicion/faturamento/Xml.vue')},

      { path: 'adicion/utilitarios/tabelas/funcionarios', component: () => import('../components/modulos/erp/adicion/utilitarios/gerais/TableFuncionarios.vue')},
      { path: 'adicion/utilitarios/tabelas/departamentos', component: () => import('../components/modulos/erp/adicion/utilitarios/gerais/TableDepartamentos.vue')},
      { path: 'adicion/utilitarios/tabelas/cidades', component: () => import('../components/modulos/erp/adicion/utilitarios/gerais/TableCidades.vue')},
      { path: 'adicion/utilitarios/tabelas/grupopessoas', component: () => import('../components/modulos/erp/adicion/utilitarios/gerais/TableGrupodePessoas.vue')},
      { path: 'adicion/utilitarios/tabelas/pessoas/fichafinanceira/:filial/:id', component: () => import('../components/modulos/erp/adicion/utilitarios/gerais/PessoasFichaFinanceira.vue')},
      { path: 'adicion/utilitarios/tabelas/pessoas', component: () => import('../components/modulos/erp/adicion/utilitarios/gerais/TablePessoas.vue')},
      { path: 'adicion/utilitarios/tabelas/transportadoras', component: () => import('../components/modulos/erp/adicion/utilitarios/gerais/TableTransportadoras.vue')},
      { path: 'adicion/utilitarios/tabelas/feriados', component: () => import('../components/modulos/erp/adicion/utilitarios/gerais/TableFeriados.vue')},
      { path: 'adicion/utilitarios/tabelas/tiposocorrencias', component: () => import('../components/modulos/erp/adicion/utilitarios/gerais/TableTiposOcorrencias.vue')},
      { path: 'adicion/utilitarios/tabelas/cargosfuncionarios', component: () => import('../components/modulos/erp/adicion/utilitarios/gerais/TableCargosFuncionarios.vue')},

      { path: 'adicion/faturamento/relatorios/notas', component: () => import('../components/modulos/erp/adicion/faturamento/relatorios/Notas.vue')},
  ]
}]
