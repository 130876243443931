import { defineStore } from 'pinia'
import { server } from '../../boot/axios'

export const useEmpresaStore = defineStore('empresa', {

    state: () => ({
        items: [],
        empresa: {fantasia:'BRANCO', uuid: ''},
        modulos: []
    }),
    actions: {
        loadEmpresas() {
            return new Promise((resolve, reject) => {
                server.get('/api/sanctum/esquemas')
                    .then(response => {
                        this.setEmpresas(response.data.data)
                        resolve(response.data.data)
                    })
                    .catch(error => {
                        reject(error);
                    })
            });
        },

        loadModulos() {
            return new Promise((resolve, reject) => {
                server.get('/api/modulos',{
                    params: { uuid: this.empresa.uuid }
                })
                    .then(response => {
                        this.modulos = response.data
                        resolve(response.data.data)
                    })
                    .catch(error => {
                        reject(error.response);
                    })
            });
        },
        markAsEmpresa (params) {
            return new Promise((resolve, reject) => { 
                server.post('/api/sanctum/empresa',{
                    'empresa' : params.uuid
                })
                .then(response => {
                    localStorage.setItem('uuid', response.data.empresa);
                    localStorage.setItem('fantasia', response.data.fantasia);
                    this.empresa.uuid = response.data.empresa
                    this.empresa.fantasia = response.data.fantasia
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error)
                })
            });
        },

        setEsquema(uuid){
            return new Promise((resolve, reject) => {
                        server.post('/api/sanctum/empresa',{
                            'empresa' : uuid
                        })
                        .then(function (response){
                            localStorage.setItem('uuid', uuid);
                            localStorage.setItem('fantasia', response.data.fantasia);
                            resolve(response.data)
                        })
                        .catch(error =>{
                            reject(error)
                        })
            })
        },

        setEmpresas(data){
            this.items = data
        }

    }
})